import './IvergramaContactForm.css';

import {
	CarpoolSuccessFailureModal,
	PBInput,
	PButton,
} from "../../../components";
// import { KuposButton, KuposErrorSuccessModal, KuposInput } from '../../../common';
import React, { useState } from 'react';

// import { AppData } from '../../../../services';
// import CommonService from '../../../../services/commonService';
import { CommonService } from "../../../services";
// import { bindActionCreators } from 'redux';
// import { connect } from 'react-redux';
import { connectData } from '../../../redux';
import getIcons from '../../functions/getIcons/getIcons';

// import { withTranslation } from 'react-i18next';

// import * as actions from '../../redux/actions';
// import * as commonActions from '../../../common/redux/actions';
// import * as homeActions from '../../../home/redux/actions';



const ivergramaIcon1 =
	'/assets/ivergrama/Services/Ivergrama-Website-Services-1.svg';
const ivergramaIcon2 =
	'/assets/ivergrama/Services/Ivergrama-Website-Services-2.svg';
const ivergramaIcon3 =
	'/assets/ivergrama/Services/Ivergrama-Website-Services-3.svg';
const ivergramaIcon4 =
	'/assets/ivergrama/Services/Ivergrama-Website-Services-4.svg';

const ivergramaIconsAndTexts = [
	{
		image: ivergramaIcon1,
		caption: 'Turismo Nacional o Internacional',
		line: true,
	},
	{ image: ivergramaIcon2, caption: 'Traslado de Personal', line: true },
	{ image: ivergramaIcon3, caption: 'Movilización para Eventos', line: true },
	{ image: ivergramaIcon4, caption: 'Viajes Especiales' },
];
let initialState = {
	name: null,
	nameError: false,
	mobile: null,
	mobileError: false,
	email: null,
	emailError: false,
	message: null,
	messageError: false,
};
const getIconsAndTexts = (operator) => {
	const name = operator && operator.operator_name;
	if (name === 'ivergrama') {
		return ivergramaIconsAndTexts;
	} else {
		return [];
	}
};

const getContactInfo = (operator) => {
	const ivergramaContactInfo = [
		{
			image: getIcons('contactMail'),
			caption: operator && operator.email && operator.email,
			line: true,
		},
		{
			image: getIcons('contactAddress'),
			caption: operator && operator.address && operator.address,
			line: true,
		},
	];

	const name = operator && operator.operator_name;
	if (name === 'ivergrama') {
		return ivergramaContactInfo;
	} else {
		return [];
	}
};



const IvergramacontactForm = (props) => {
	const initialState = {
		name: null,
		phone: null,
		email: null,
		comment: null,
		nameError: false,
		phoneError: false,
		emailError: false,
		commentError: false,
	};
	const [state, setState] = useState(initialState);

	const operator =
		JSON.parse(localStorage.getItem("op")) || props.data.operator;

	const inpChange = (value, type) => {
		if (type == "phone") {
			value = CommonService.phoneNumberFormat(value);
			setState({
				...state,
				[type]: value,
				[type + "Error"]: false,
			});
		} else {
			setState({ ...state, [type]: value, [type + "Error"]: false });
		}
	};

	const validate = () => {
		let errorCount = 0;
		if (!state.name) {
			setState({
				...state,
				nameError: true,
				nameErrorMessage: "Ingresa tu nombre",
			});
			errorCount++;
		} else if (!state.phone) {
			setState({
				...state,
				phoneError: true,
				phoneErrorMessage: "Ingresa tu número de teléfono",
			});
			errorCount++;
		} else if (!state.email) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (state.email && !CommonService.isEMailValid(state.email)) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (!state.comment) {
			setState({
				...state,
				commentError: true,
				commentErrorMessage: "Inserta tu comentario",
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		} else {
			return true;
		}
	};

	const onSubmit = () => {

		if (!validate()) {

			return;
		}

		let data = {
			name: state.name,
			email: state.email,
			phone_number: state.phone,
			comments: state.comment,
			operator_id: operator.operator_id,
			operator_name: operator.operator_name,
		};

		const operatorsRequiringLastName = []; // add operator name in Array for which you need last_name.

		if (operatorsRequiringLastName.includes(operator.operator_name) && state.last_name) {
			data.last_name = state.last_name;
		}
		if (operator && (operator.operator_name === 'ivergrama' || operator.operator_name === 'busnorte' || operator.operator_name === 'aeroquinta')) {

			// if (operator && (operator.operator_name === "tarapaca" || operator.operator_name === "santamaria" || operator.operator_name === "biaggini")) {

			props.opsiteContactInfo({
				callback: (result) => onContactInfoResponse(result),
				data: data,
			});
		}
	};


	const onContactInfoResponse = (res) => {
		if (res && res.data && res.data.message) {
			setState({
				...state,
				showModal: true,
				responseMessage: res.data.message,
				status: res.data.status,
			});
		}
	};
	return (
		<div className="themes-theme-three-contact-form new-container">
			<div className=" form-section">
				<div className="contact ">
					<div className="contact-header">
						<div
							className="contact-header-text font13"
						// style={{
						//   color:
						//     operator &&
						//       operator.color_codes &&
						//       operator.color_codes.primary_text_color
						//       ? operator.color_codes.primary_text_color
						//       : "",
						//   borderBottom:
						//     operator &&
						//       operator.color_codes &&
						//       operator.color_codes.button_color
						//       ? `2px solid ${operator.color_codes.button_color}`
						//       : "",
						// }}
						>
							<h2 class="bold-text">Contáctanos</h2>
						</div>
					</div>
					<div
						className="contact-bottom"

					>
						<div className="align-contact-inputs">
							<div className="contact-input-item">
								<style>
									.ThemeTwoContactForm .contact .contact-bottom
									.contact-input-label less-bold-text {"{"}
									color:
									{operator &&
										operator.color_codes &&
										operator.color_codes.secondary_text_color &&
										operator.color_codes.secondary_text_color}
									{"}"}
								</style>
								<div className="contact-input-label less-bold-text ">Nombre</div>
								<PBInput
									// label='Nombre'
									placeholder="Nombre"
									value={state.name}
									onChange={(text) => inpChange(text, "name")}
									// onBlur={(text) => onBlur(text, "name")}
									error={state.nameError ? true : false}
									errorMessage={state.nameError ? state.nameErrorMessage : ""}
									// containerStyle={{
									//   padding: "0",
									//   border: 0,
									// }}
									inputClass={{
										padding: "5px",
									}}
								/>
							</div>

							<div className="contact-input-item">
								<div className="contact-input-label less-bold-text ">Teléfono</div>
								{/* <KuposInput type="number" placeholder="Teléfono" /> */}
								<PBInput
									// label='Teléfono'
									placeholder="Teléfono"
									value={state.phone}
									onChange={(text) => inpChange(text, "phone")}
									// onBlur={(text) => onBlur(text, "phone")}
									error={state.phoneError ? true : false}
									errorMessage={
										state.phoneError ? state.phoneErrorMessage : ""
									}
									// containerStyle={{
									//   padding: "0",
									//   border: 0,
									// }}
									inputClass={{
										padding: "5px",
									}}
								/>
							</div>

							<div className="contact-input-item">
								<div className="contact-input-label less-bold-text ">Email</div>
								<PBInput
									// label='Email'
									placeholder="Email"
									value={state.email}
									onChange={(text) => inpChange(text, "email")}
									// onBlur={(text) => onBlur(text, "email")}
									error={state.emailError ? true : false}
									errorMessage={
										state.emailError ? state.emailErrorMessage : ""
									}
									// containerStyle={{
									//   padding: "0",
									//   border: 0,
									// }}
									inputClass={{
										padding: "5px",
									}}
								/>
								{/* <KuposInput type="email" placeholder="Email" /> */}
							</div>
						</div>

						{/* <div className=" contact-input-item" style={{ marginLeft: -10 }}>
							<div className="contact-input-label less-bold-text ">Mensaje</div>
							<textarea
								className="text-area font14"
								rows={6}
								placeholder="Mensaje"
								onChange={(event) => inpChange(event.target.value, "comment")}
							/>
							<span className="error" style={{ color: "red" }}>
								{state.commentError ? state.commentErrorMessage : null}
							</span>
						</div> */}
						<div className='contact-input-item'>
							<div className='contact-input-label less-bold-text new-bold-font'>Comentario</div>
							{/* {operator.operator_name === "aeroquinta" ? ( */}
							<PBInput
								placeholder="Opcional"
								value={state.comment}
								onChange={(text) => inpChange(text, "comment")}
								error={state.commentError ? true : false}
								errorMessage={state.commentError ? state.commentErrorMessage : ""}
								containerStyle={{
									padding: "5",
									// border: 0,
								}}
								inputClass={{
									padding: "5px",
								}}
							/>
							{/* // ) : ( */}
							{/* // 	<textarea */}
							{/* // 		className="text-area font14"
						// 		rows={6}
						// 		placeholder="Mensaje"
						// 		onChange={(event) => inpChange(event.target.value, "comment")}
						// 	/>
						// )} */}
							<span className="error" style={{ color: "red" }}>
								{state.commentError ? state.commentErrorMessage : null}
							</span>
						</div>
						<div
							className="contact-button "
							style={{
								marginLeft: 10,
								marginRight: 10,
								marginTop: 10,
							}}
						>
							<button onClick={onSubmit} className='btn submit-btn  bold-text' style={{
								backgroundColor: operator && (
									operator.operator_name === "aeroquinta" ? "#4079bc" :
										operator.operator_name === "busnorte" ? "#d9d528" :
											operator.operator_name === "ivergrama" ? "#1a231d" :
												""
								),
								color: operator && (
									operator.operator_name === "aeroquinta" ? "#fff" :
										operator.operator_name === "busnorte" ? "#206a2d" :
											operator.operator_name === "ivergrama" ? "#fff" :
												""
								),
								padding: operator && (
									operator.operator_name === "ivergrama" ? "" : "10px 0px"
								),
							}}>
								{operator && operator.operator_name === "ivergrama" ?
									<img src={getIcons('sendBtn', operator)} alt="" /> : ""}
								ENVIAR</button>

							{/* <PButton title={"ENVIAR"} onPress={onSubmit} /> */}
							<CarpoolSuccessFailureModal
								showModal={state.showModal}
								success={state.status ? true : false}
								smallIcon={true}
								bodyText={state.responseMessage}
								buttonText={"OK"}
								onButtonPress={() => {
									setState({ showModal: false });
									window.location.reload();
								}}
							/>
						</div>
					</div>
				</div>
			</div>

			{props.homeImage ? (
				<div className="home-image">
					<img src={getIcons('contactFormImg', operator)} alt="" />
				</div>
			) : null}


			{props.contact ? <ContactInfo operator={operator} /> : null}

		</div>
	);
};

// const ContactInfo = ({ operator }) => {
// 	const renderIconsAndText = (item, key, length) => {
// 		return (
// 			<React.Fragment>
// 				<div className='item'>
// 					<div>
// 						<img
// 							src={item.image}
// 							alt='Giras de estudio'
// 							style={{ width: '35px', height: 'auto' }}
// 							className='icon'
// 						/>
// 					</div>
// 					<div className='caption '>{item.caption}</div>
// 				</div>
// 				{item.line ? (
// 					<hr
// 						style={{
// 							borderColor:
// 								operator &&
// 								operator.color_codes &&
// 								operator.color_codes.secondary_color,
// 						}}
// 					/>
// 				) : null}
// 			</React.Fragment>
// 		);
// 	};
// 	return (
// 		<div className='contactus'>
// 			<h5 className='less-bold-text new-bold-font'>Envíanos tus dudas o consultas a:</h5>
// 			{getContactInfo(operator).map((item, key) => {
// 				return renderIconsAndText(item, key, getIconsAndTexts().length);
// 			})}
// 			<h5 className='less-bold-text new-bold-font'>Síguenos en nuestras redes socialesttttt</h5>
// 			<style>
// 				.contactus .fb{'{'}
// 				background-image: url({getIcons('fb')}); {'}'}
// 				.contactus .fb:hover{'{'}
// 				background-image: url({getIcons('fbHover')}); {'}'}
// 				.contactus .twitter{'{'}
// 				background-image: url({getIcons('twitter')}); {'}'}
// 				.contactus .twitter:hover{'{'}
// 				background-image: url({getIcons('twitterHover')}); {'}'}
// 			</styl>

// 			<div className='contact-links'>
// 				<a
// 					href='https://www.facebook.com/Buses-Ivergrama-931335030348861/'
// 					target='_blank'
// 				>
// 					<span class='fb social'></span>
// 				</a>

// 				<a href='https://twitter.com/busesiver' target='_blank'>
// 					<span class='twitter social'></span>
// 				</a>
// 			</div>
// 		</div>
// 	);
// };


//////////////////
const ContactInfo = ({ operator }) => {
	const renderIconsAndText = (item, key, length) => {
		return (
			<React.Fragment key={key}>
				<div className='item'>
					<div>
						<img
							src={item.image}
							alt='Giras de estudio'
							style={{ width: '35px', height: 'auto' }}
							className='icon'
						/>
					</div>
					<div className='caption '>{item.caption}</div>
				</div>
				{item.line ? (
					<hr
						style={{
							borderColor:
								operator &&
								operator.color_codes &&
								operator.color_codes.secondary_color,
						}}
					/>
				) : null}
			</React.Fragment>
		);
	};

	const renderLinks = () => {
		if (operator.operator_name === 'ivergrama') {
			return (
				<div className='contact-links'>
					<a
						href='https://www.facebook.com/Buses-Ivergrama-931335030348861/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<span className='fb social'></span>
					</a>
					<a
						href='https://twitter.com/busesiver'
						target='_blank'
						rel='noopener noreferrer'
					>
						<span className='twitter social'></span>
					</a>
				</div>
			);
		} else if (operator.operator_name === 'aeroquinta') {
			return (
				<div className='contact-links' style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
					<a
						href='https://www.facebook.com/'
						target='_blank'
						rel='noopener noreferrer'
					>
						{/* <span className='fb social'></span> */}
						<span className=" email" >
							<img src={getIcons('fb', operator)} alt="DIS" className="top_icon" style={{ height: "30px", width: "40px", paddingRight: "10px" }} />
						</span>
					</a>
					<a
						href='https://www.instagram.com'
						target='_blank'
						rel='noopener noreferrer'
					>
						<span className=" email" >
							<img src={getIcons('instagram', operator)} alt="DIS" className="top_icon" style={{ height: "40px", width: "40px", paddingRight: "10px" }} />
						</span>
						{/* <span className='twitter social'></span> */}

					</a>
				</div>
			);
		} else {
			// Default or additional cases can be handled here
			return null;
		}
	};

	return (
		<div className='contactus'>
			<h5 className='less-bold-text new-bold-font'>Envíanos tus dudas o consultas a:</h5>
			{getContactInfo(operator).map((item, key) => {
				return renderIconsAndText(item, key, getIconsAndTexts().length);
			})}
			<h5 className='less-bold-text new-bold-font'>Síguenos en nuestras redes sociales</h5>
			<style>
				{`
                .contactus .fb {
                    background-image: url(${getIcons('fb')});
                }
                .contactus .fb:hover {
                    background-image: url(${getIcons('fbHover')});
                }
                .contactus .twitter {
                    background-image: url(${getIcons('twitter')});
                }
                .contactus .twitter:hover {
                    background-image: url(${getIcons('twitterHover')});
                }
                `}
			</style>
			{renderLinks()}
		</div>
	);
};
/////////////////

const ServiceIcons = ({ operator }) => {
	const renderIconsAndText = (item, key, length) => {
		return (
			<React.Fragment>
				<div className='item'>
					<div>
						<img src={item.image} alt='Giras de estudio' className='icon' />
					</div>
					<div className='caption '>{item.caption}</div>
				</div>
				{item.line ? (
					<hr
						style={{
							borderColor:
								operator &&
								operator.color_codes &&
								operator.color_codes.secondary_color,
						}}
					/>
				) : null}
			</React.Fragment>
		);
	};

	return (
		<div className='services'>
			{getIconsAndTexts(operator).map((item, key) => {
				return renderIconsAndText(item, key, getIconsAndTexts().length);
			})}
		</div>
	);
};

export default connectData()(IvergramacontactForm);
