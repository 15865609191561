import "./ContactForm1.css";

import {
	CarpoolSuccessFailureModal,
	PBInput,
	PButton,
} from "../../../components";
import React, { useState } from "react";

import { CommonService } from "../../../services";
import { bindActionCreators } from "redux";
import { connectData } from "../../../redux";

const ContactForm1 = (props) => {
	const operator =
		JSON.parse(localStorage.getItem("op")) || props.data.operator;

	const [state, setState] = useState("");

	const inpChange = (value, type) => {
		if (type == "phone") {
			value = CommonService.phoneNumberFormat(value);
			setState({
				...state,
				[type]: value,
				[type + "Error"]: false,
			});
		} else {
			setState({ ...state, [type]: value, [type + "Error"]: false });
		}
	};

	const validate = () => {
		let errorCount = 0;
		if (!state.name) {
			setState({
				...state,
				nameError: true,
				nameErrorMessage: "Ingresa tu nombre",
			});
			errorCount++;
		} else if (!state.phone) {
			setState({
				...state,
				phoneError: true,
				phoneErrorMessage: "Ingresa tu número de teléfono",
			});
			errorCount++;
		} else if (!state.email) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (state.email && !CommonService.isEMailValid(state.email)) {
			setState({
				...state,
				emailError: true,
				emailErrorMessage: "Ingrese un email valido",
			});
			errorCount++;
		} else if (!state.comment) {
			setState({
				...state,
				commentError: true,
				commentErrorMessage: "Inserta tu comentario",
			});
			errorCount++;
		}

		if (errorCount > 0) {
			return false;
		} else {
			return true;
		}
	};
	const onSubmit = () => {
		if (!validate()) {
			return;
		}

		let data = {
			name: state.name,
			last_name: null,
			email: state.email,
			phone_number: state.phone,
			comments: state.comment,
			operator_id: operator.operator_id,
			operator_name: operator.operator_name,
		};
		if ((operator.operator_name === 'tepual' || operator.operator_name === 'expressonorte' || operator.operator_name === 'bahiaazul' || operator.operator_name === 'erbuc' || operator.operator_name === 'trrass')) {
			props.opsiteContactInfo({
				callback: (result) => onContactInfoResponse(result),
				data: data,
			});
		}
	};
	const onContactInfoResponse = (res) => {
		console.log("response from contact info", res);
		if (res && res.data && res.data.message) {
			setState({
				...state,
				showModal: true,
				responseMessage: res.data.message,
				status: res.data.status,
			});
		}
	};
	console.log("props in contactform1", props);
	return (
		<div className="themes-contact-form-1">
			<div className="new-container ">
				<div className="contact">
					<div
						className="contact-header"
						style={{
							background:
								operator &&
									operator.color_codes &&
									operator.color_codes.secondary_color
									? operator.color_codes.secondary_color
									: "",
						}}
					>
						<style>
							.themes-contact-form-1 .contact-header{"{"}
							background:
							{operator &&
								operator.color_codes &&
								operator.color_codes.secondary_color
								? operator.color_codes.secondary_color
								: ""}
							,{"}"}
						</style>
						<div
							className="contact-header-text font13"
							style={{
								borderBottom:
									operator && operator.operator_name === "trrass"
										? "2px solid #fff"
										: "2px solid rgb(255, 239, 0)",
							}}
						>
							Contáctanos
						</div>
					</div>
					<div className="contact-bottom">
						<div className="align-contact-inputs">
							<div className="contact-input-item">
								{/* <div className="contact-input-label font11">Nombre</div> */}
								<PBInput
									label="Nombre"
									placeholder="Nombre"
									type="text"
									value={state.name}
									onChange={(text) => inpChange(text, "name")}
									// onBlur={(text) => onBlur(text, "name")}
									error={state.nameError ? true : false}
									errorMessage={state.nameErrorMessage}
									containerStyle={{ padding: "0" }}
									inputClass={{ padding: "5px" }}
								/>
							</div>

							<div className="contact-input-item">
								{/* <div className="contact-input-label font12">Teléfono</div> */}
								{/* <KuposInput type="number" placeholder="Teléfono" /> */}
								<PBInput
									label="Teléfono"
									placeholder="Teléfono"
									value={state.phone}
									onChange={(text) => inpChange(text, "phone")}
									// onBlur={(text) => onBlur(text, "phone")}
									error={state.phoneError ? true : false}
									errorMessage={state.phoneErrorMessage}
									containerStyle={{ padding: "0" }}
									inputClass={{ padding: "5px" }}
								/>
							</div>

							<div className="contact-input-item">
								{/* <div className="contact-input-label font12">Email</div> */}
								<PBInput
									label="Email"
									placeholder="Email"
									value={state.email}
									onChange={(text) => inpChange(text, "email")}
									// onBlur={(text) => onBlur(text, "email")}
									error={state.emailError ? true : false}
									errorMessage={state.emailErrorMessage}
									containerStyle={{ padding: "0" }}
									inputClass={{ padding: "5px" }}
								/>
								{/* <KuposInput type="email" placeholder="Email" /> */}
							</div>
						</div>

						<div className=" contact-input-item" style={{ marginLeft: -10 }}>
							{/* <div className="contact-input-label font12">Mensaje</div> */}
							<div
								className="font14"
								style={{
									marginBottom: 5,
									fontWeight: "bold",
								}}
							>
								Mensaje
							</div>
							<textarea
								className="text-area font14"
								rows={6}
								placeholder="Mensaje"
								onChange={(e) => inpChange(e.target.value, "comment")}
							/>
							<span className="error">
								{state.commentError ? state.commentErrorMessage : null}
							</span>
						</div>

						<div
							className="contact-button "
							style={{ marginLeft: -10, marginTop: 10 }}
						>
							<PButton
								title={"ENVIAR"}
								operator={operator}
								onPress={onSubmit}
							/>
							{/* <KuposButton className="px-20">ENVIAR</KuposButton> */}
						</div>
					</div>
				</div>
			</div>
			<CarpoolSuccessFailureModal
				showModal={state.showModal}
				success={state.status ? true : false}
				smallIcon={true}
				bodyText={state.responseMessage}
				buttonText={"OK"}
				onButtonPress={() => {
					setState({
						showModal: false,
					});
					props.history.push("/");
				}}
			/>
		</div>
	);
};

export default connectData()(ContactForm1);
