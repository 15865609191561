import React, { useState } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { connectData } from '../../../redux';
import Header from '../../common/header/Header';
import HeroSection from '../../common/HeroSection/HeroSection';
import ThemeSevenFooter from '../common/ThemeSevenFooter';
import './ThemeSevenFrequentCard.css';

const data = [
  {
    title: '¿Qué es Tarjeta Viajero Frecuente?',
    des: `<p>El programa viajero frecuente es un beneficio para nuestros clientes para que se hagan socios de <span class="bold-text">Buses Fernández Ltda.,</span> el que les permite acumular kilómetros en una cuenta personal, los que se podrán canjear por pasajes en el tramo <span class="bold-text">PUNTA ARENAS – PUERTO NATALES</span> o viceversa.</p>
      <p>Adicional a lo anterior, le permite acceder a una tarifa preferencial en pasajes en el tramo <span class="bold">PUNTA ARENAS – PUERTO NATALES</span> o viceversa.</p>
      <p>Participas en sorteos programados periódicamente, que le permitirán acceder a los premios definidos para estos.</p>
      `,
    img: '/assets/fernandez/FT/BusesFernandez-Website-FrequentTraveler-FrequentTravelerCard-CardImage.png',
    active: true,
  },

  {
    title: '¿Cómo inscribirme?',
    des: `
      <p>Tiene que dirigirse a nuestros puntos de ventas de pasajes, tanto en Punta Arenas como Puerto Natales y completar el formulario respectivo. <br> No olvide portar su cédula de Identidad.</p>
      <p>También nos puede solicitar su inscripción a través de la página web en la sección contacto; ahí nos envía un mensaje con sus datos y nosotros lo contactaremos para gestionar el formulario respectivo.</p>
      `,

    active: false,
  },

  {
    title: '¿Cuál es el costo de la tarjeta?',
    des: `
            <p>Tu tarjeta de viajero frecuente es completamente gratuita, obtén la tuya en nuestras oficinas de atención.</p>
           
      `,
    active: false,
  },

  {
    title: '¿Cómo compro pasajes?',
    des: `<p>De manera presencial: en cualquiera de nuestros puntos de ventas de pasajes, presentando tu <span class="bold">TARJETA DE VIAJERO FRECUENTE</span> y su cédula de Identidad.</p>`,
    active: false,
  },

  // {
  //   title: '¿Cómo acumulo kilómetros?',
  //   des: `
  //           <p>-Al inscribirse como <span class="bold">VIAJERO FRECUENTE,</span> obtendrá inmediatamente 250 kilómetros, los que serán abonados a su cuenta personal.</p>
  //           <p>-Por la compra de pasajes en el tramo <span class="bold">PUNTA ARENAS – PUERTO NATALES</span> o viceversa obtendrá 250 kilómetros.</p>
  //           <p>-El día de su cumpleaños abonaremos 250 kilómetros a su cuenta.</p>
  //         `,
  //   active: false,
  // },

  // {
  //   title: '¿Cómo canjeo mis kilómetros?',
  //   des: `
  //           <p>Por cada 12.000 (doce mil) kilómetros acumulados en su cuenta personal, podrá canjear 01 (un) pasaje para el tramo <span>PUNTA ARENAS – PUERTO NATALES</span> o viceversa.</p>
  //           <p>Los tramos intermedios no aplican para este beneficio, es decir, no se puede canjear pasajes ni tampoco acumular puntos.</p>
  //           <p>Para realizar el canje se debe presentar en nuestros puntos de ventas de pasajes con su <span class="bold">TARJETA DE VIAJERO FRECUENTE</span> y su cédula de identidad.</p>
  //           <p>Si necesita que otra persona realice el canje por usted, entonces deberá presentar un poder notarial que lo individualice como socio e identifique a la persona que autorizará para hacer dicha gestión.</p>
  //   `,
  //   active: false,
  // },
];

const ThemeSevenFrequentCard = (props) => {
  const [state, setState] = useState(data);
  const [selected, setSelected] = useState(state[0]);

  const handleSubmit = (item) => {
    const newState = state.map((d) => {
      d.active = d.title === item.title ? true : false;
      return d;
    });
    setState(newState);
    setSelected(item);
  };

  const createMarkup = (data) => {
    return {
      __html: data,
    };
  };

  return props.data.operator ? (
    <IonPage>
      <IonContent>
        <Header />
        <div className='themes-theme-seven-frequent-card'>
          <HeroSection
            height='300px'
            title={'Viajero frecuente'}
            heroImage='/assets/fernandez/FT/BusesFernandez-Website-FrequentTraveler-FrequentTravelerCard-Banner.jpg'
          />

          <div className='cards new-container'>
            <h3>
              <span>Tarjeta de viajero frecuente</span>
            </h3>

            <div className='row new-container'>
              <div className='wd-full'>
                {state.map((val, i) => {
                  return (
                    <div
                      key={i}
                      className={`tab ${val.active ? 'active' : ''}`}
                      onClick={() => handleSubmit(val)}
                    >
                      {val.title}
                    </div>
                  );
                })}
              </div>

              <div className='tab-content '>
                {selected.img ? <img src={selected.img} alt='' /> : null}
                <div dangerouslySetInnerHTML={createMarkup(selected.des)} />
              </div>
            </div>
          </div>
        </div>
        <ThemeSevenFooter />
      </IonContent>
    </IonPage>
  ) : null;
};

export default connectData()(ThemeSevenFrequentCard);
