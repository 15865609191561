// @flow

import {
  ACCOUNT_CONFIRMATION,
  ADD_BIP_CARD,
  ADD_MY_CARDS,
  AIRPORT_TRANSFER_BOOK,
  AIRPORT_TRANSFER_GET_ROUTES,
  APPLY_PROMO_CODE,
  PROMO_CODE_AIRPORT_DISCOUNTS,
  BOOK_TENTATIVE_TICKET,
  CANCEL_TICKET,
  CANCEL_TICKET_AT,
  CARPOOL_BOOK_TRIP,
  CARPOOL_CANCEL_TRIP,
  CARPOOL_DRIVER_ARRIVE_TRIP,
  CARPOOL_DRIVER_CREATE_ROUTE,
  CARPOOL_DRIVER_CREATE_TRIP,
  CARPOOL_DRIVER_FINISH_TRIP,
  CARPOOL_DRIVER_GET_TRIPS,
  CARPOOL_DRIVER_GET_TRIP_DETAILS,
  CARPOOL_DRIVER_GET_TRIP_ORDERS,
  CARPOOL_DRIVER_PUBLISH_TRIP,
  CARPOOL_DRIVER_REGISTER,
  CARPOOL_DRIVER_START_TRIP,
  CARPOOL_DRIVER_UPDATE_LOCATION,
  CARPOOL_GET_ORDER_DETAILS,
  CARPOOL_GET_SEARCH_TRIPS,
  CARPOOL_GET_TRIPS,
  CARPOOL_RANK_TRIP,
  CARPOOL_UPLOAD_PIC,
  DELETE_API_CALL,
  DELETE_BIP_CARD,
  DELETE_MY_CARDS,
  FORGOT_PASSWORD,
  GENERATE_OTP,
  GET_API_CALL,
  GET_AT_BLOCK_TIME,
  GET_AT_TERMINALS,
  GET_AT_TICKETS,
  GET_BIP_CARDS,
  GET_CARD_ANALYTICS,
  GET_CITIES,
  GET_CITIES_SEARCH,
  GET_CITIES_UNIQUE,
  GET_CITIES_UNIQUE_TRAIN,
  GET_CITY_PAIRS,
  GET_CORPORATE_DISCOUNT,
  GET_CUSTOMER_CARDS,
  GET_CUSTOMER_INFO,
  GET_CUSTOMER_PROFILE,
  GET_ENVIRONMENT_THINGS,
  GET_FACEBOOK_DATA,
  GET_INSTANT_DISCOUNT,
  GET_KUPOS_GO_TICKETS,
  GET_MARKER_ICONS,
  GET_META_DATA,
  GET_MY_TICKETS,
  GET_OFFICE_DETAILS,
  GET_OPERATOR_SITE,
  GET_PLACES,
  GET_QR_IMAGE,
  GET_QR_INFO,
  GET_RECHARGE_LOCATIONS,
  GET_RECIPIENT_INFO,
  GET_SEAT_TYPES,
  GET_SERVICES,
  GET_SERVICES_V2,
  GET_SERVICES_V3,
  GET_SERVICE_DETAILS,
  GET_SERVICE_DETAILS_V2,
  GET_SERVICE_DETAILS_V3,
  GET_TICKET_DETAILS,
  GET_TICKET_DETAILS_AT,
  GET_TICKET_DETAILS_KUPOS,
  GET_TICKET_DETAILS_V3,
  GET_TRAIN_TICKETS,
  GET_TXN_HISTORY,
  GET_VIRTUAL_MONEY,
  GET_WALLET_RECHARGE_REQUEST,
  KUPOS_GO_POST_TRIP_DETAILS,
  LOGIN,
  LOGOUT_GOOGLE,
  OPSITE_CONTACT_INFO,
  POST_API_CALL,
  PUT_API_CALL,
  RECHARGE_BIP_CARD,
  REGISTER,
  REQUEST_MONEY,
  RESET_NEW_PASSWORD,
  SPECIAL_SERVIVCE,
  TRANSFER_MONEY,
  UPDATE_BIP_CARD,
  UPDATE_CUSTOMER_PROFILE,
  UPDATE_FAVOURITES,
  VALIDATE_BIP_CARD,
  VALIDATE_CUSTOMER,
  VALIDATE_MOT,
  VALIDATE_MOT_SMS,
  VALIDATE_OTP,
  fetchDataActionCreators,
} from "./actions";
import { AppData, App_Service } from "../../../services";
import { all, call, fork, put, take } from "redux-saga/effects";

import CommonService from "../../../services/commonService";
import ReducerUtilService from "../../services/reducerUtilService";
import { groupBy } from "lodash";

const callback = (response, callback) => {
  if (callback)
    if (response.result === "ok" && response.data) {
      callback({ success: true, data: response.data });
    } else {
      callback({
        success: false,
        error: "Login Failed try after sometime",
      });
    }
};

export function* asyncGetFacebookUserData({ payload }) {
  const { facebookToken } = payload;

  // eslint-disable-next-line
  const url = `https://graph.facebook.com/v2.11/me?access_token=${facebookToken}&fields=id,name,email,picture{url}`;

  try {
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok") {
      yield put(
        fetchDataActionCreators.getFacebookUserDataSuccess(response.data)
      );
    }
  } catch (e) {
    console.log(e);
  }
}

export function* watchGetFacebookUserData() {
  while (true) {
    const action = yield take(GET_FACEBOOK_DATA);
    yield* asyncGetFacebookUserData(action);
  }
}

export function* login({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/login?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({
        success: false,
        error: "Login Failed try after sometime",
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* opsiteContactInfo({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/opsite_contact_info";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("opsite_contact_info", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({
        success: false,
        error: "Login Failed try after sometime",
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchOpsiteContactInfo() {
  while (true) {
    const action = yield take(OPSITE_CONTACT_INFO);
    yield* opsiteContactInfo(action);
  }
}

// Get Api Call ----
export function* asyncGetApiCall({ payload }) {
  // const { facebookToken } = payload;
  const operator = JSON.parse(localStorage.getItem("op"));
  // eslint-disable-next-line
  let url = "";

  if (operator.theme_type === 11) {
    url = AppData.BASE_URL + payload.url;
  } else {
    url = AppData.BASE_URL + "api/" + payload.url;
  }

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    callback(response, payload.callback);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetApiCall() {
  while (true) {
    const action = yield take(GET_API_CALL);
    yield* asyncGetApiCall(action);
  }
}

// POST Api Call ----
export function* asyncPostApiCall({ payload }) {
  // const { facebookToken } = payload;
  const operator = JSON.parse(localStorage.getItem("op"));

  // eslint-disable-next-line
  let url = "url";

  if (operator.theme_type === 11) {
    url = AppData.BASE_URL + payload.url;
  } else {
    url = AppData.BASE_URL + "api/" + payload.url;
  }

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    callback(response, payload.callback);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}
export function* watchPostApiCall() {
  while (true) {
    const action = yield take(POST_API_CALL);
    yield* asyncPostApiCall(action);
  }
}
// PUT Api Call ----
export function* asyncPutApiCall({ payload }) {
  // const { facebookToken } = payload;

  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/" + payload.url;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "PUT",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    callback(response, payload.callback);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchPutApiCall() {
  while (true) {
    const action = yield take(PUT_API_CALL);
    yield* asyncPutApiCall(action);
  }
}
// DELETE Api Call ----
export function* asyncDeleteApiCall({ payload }) {
  // const { facebookToken } = payload;

  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/" + payload.url;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "DELETE",
    });
    yield put(fetchDataActionCreators.hideLoader());
    callback(response, payload.callback);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchDeleteApiCall() {
  while (true) {
    const action = yield take(DELETE_API_CALL);
    yield* asyncDeleteApiCall(action);
  }
}

export function* watchLogin() {
  while (true) {
    const action = yield take(LOGIN);
    yield* login(action);
  }
}

export function* register({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/register?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({
        success: false,
        error: "Signup Failed try after sometime",
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchRegister() {
  while (true) {
    const action = yield take(REGISTER);
    yield* register(action);
  }
}

export function* googleLogout({ payload }) {
  // eslint-disable-next-line
  // yield put(fetchDataActionCreators.showLoader());
  const url =
    "https://accounts.google.com/o/oauth2/revoke?token=" + payload.token;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      noContentJson: true,
      noAuth: true,
    });
    // yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      // payload.callback({success:true,data:response.data});
    } else {
      payload.callback({
        success: false,
        error: "Signup Failed try after sometime",
      });
    }
  } catch (e) {
    // yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGoogleLogout() {
  while (true) {
    const action = yield take(LOGOUT_GOOGLE);
    yield* googleLogout(action);
  }
}

export function* forgotPassword({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/request_password?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({ success: false, error: "Failed try after sometime" });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchForgotPassword() {
  while (true) {
    const action = yield take(FORGOT_PASSWORD);
    yield* forgotPassword(action);
  }
}

export function* validateCustomer({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/validate_customer";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({
        success: false,
        error: "Signup Failed try after sometime",
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchValidateCustomer() {
  while (true) {
    const action = yield take(VALIDATE_CUSTOMER);
    yield* validateCustomer(action);
  }
}

export function* accountConfirmation({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/customer/confirm_account?confirmation_token=" +
    payload.token;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({ success: false, error: "Failed try after sometime" });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchAccountConfirmation() {
  while (true) {
    const action = yield take(ACCOUNT_CONFIRMATION);
    yield* accountConfirmation(action);
  }
}

export function* resetNewPassword({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/customer/change_password?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("login", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({ success: false, error: "Failed try after sometime" });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchResetNewPassword() {
  while (true) {
    const action = yield take(RESET_NEW_PASSWORD);
    yield* resetNewPassword(action);
  }
}

export function* asyncGetMetaData({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/masters.json?";

  try {
    // if(!payload.metaData)
    //   yield put(fetchDataActionCreators.showLoader());
    const response = yield call(App_Service, { url, method: "GET" });

    // console.log("response ----------------->", response);
    if (response.result === "ok" && response.data.result) {
      yield put(
        fetchDataActionCreators.getMetaDataSuccess(response.data.result)
      );
    }
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    } else {
      payload.callback({ success: false });
    }
    payload.callback();
    // if(!payload.metaData)
    //   yield put(fetchDataActionCreators.hideLoader());
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetMetaData() {
  while (true) {
    const action = yield take(GET_META_DATA);
    yield* asyncGetMetaData(action);
  }
}

export function* asyncGetCities({ payload }) {
  // eslint-disable-next-line

  try {
    if (!payload.cities) {
      yield put(fetchDataActionCreators.showLoader());
    }
    let url = AppData.BASE_URL + "api/uniq_cities.json?";
    const response = yield call(App_Service, { url, method: "GET" });

    // console.log("response ----------------->", response);
    let cities;
    if (response.result === "ok" && response.data.body) {
      cities = CommonService.formatData(response.data.body);
      // yield put(fetchDataActionCreators.getCitiesSuccess(cities));
      payload.callback({ success: true, citiesSuccess: cities });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* asyncGetCityPairs({ payload }) {
  try {
    // console.log('hlhjhkhkjkjhkhhkjhkjhkjhhjhh')
    const url =
      "https://gds.ticketsimply.us/gds/api/city_pairs.json?&api_key=TSQPGXAPI33366111";
    // url = AppData.BASE_URL + 'api/city_pairs.json?';
    const response2 = yield call(App_Service, { url, method: "GET" });

    console.log("response2 ----------------->", response2);
    if (response2.result === "ok" && response2.result) {
      // yield put(fetchDataActionCreators.setCities(response2.data.result));
      if (payload.callback) {
        payload.callback({ success: true, setCities: response2.data.result });
      }
    }
    if (!payload.cities) {
      yield put(fetchDataActionCreators.hideLoader());
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetCities() {
  while (true) {
    // yield put(fetchDataActionCreators.showLoader());
    const action = yield take(GET_CITIES);
    yield call(() => asyncGetCities(action));

    // console.log("888888888888888888888888888888888888888888888888888888")
    // yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetCityPairs() {
  while (true) {
    // yield put(fetchDataActionCreators.showLoader());
    const action = yield take(GET_CITY_PAIRS);
    yield* asyncGetCityPairs(action);
    // yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* asyncGetCitiesUnique({ payload }) {
  // eslint-disable-next-line
  console.log(payload, "payload-----");
  const operator = JSON.parse(localStorage.getItem("op"));

  const operator_id = payload.operator_id;
  try {
    if (!payload.cities) {
      yield put(fetchDataActionCreators.showLoader());
    }
    let url = "";
    if (operator.theme_type === 11) {
      url = AppData.BASE_URL + `airport/${operator.operator_name}/api/cities`;
    } else {
      url =
        AppData.BASE_URL +
        `api/${
          operator_id
            ? `operator_uniq_cities?travel_id=${operator_id}`
            : "uniq_cities"
        }`;
    }
    const response = yield call(App_Service, { url, method: "GET" });
    // console.log("response ----------------->", response);
    if (!payload.cities) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    if (response.result === "ok" && response.data) {
      // yield put(fetchDataActionCreators.getCitiesUniqueSuccess(cities));
      if (operator.theme_type === 11) {
        payload.callback({
          success: true,
          cities:
            (response.data &&
              response.data.data &&
              response.data.data.cities) ||
            [],
        });
      } else {
        payload.callback({ success: true, cities: response.data });
      }
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetCitiesUnique() {
  while (true) {
    // yield put(fetchDataActionCreators.showLoader());
    const action = yield take(GET_CITIES_UNIQUE);
    yield* asyncGetCitiesUnique(action);
    // yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* asyncGetCitiesUniqueTrain({ payload }) {
  // eslint-disable-next-line

  try {
    if (!payload.cities) {
      yield put(fetchDataActionCreators.showLoader());
    }
    let url = AppData.BASE_URL + "api/train_cities";
    const response = yield call(App_Service, { url, method: "GET" });

    console.log("response ----------------->", response);
    if (!payload.cities) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    if (response.result === "ok" && response.data) {
      // yield put(fetchDataActionCreators.getCitiesUniqueTrainSuccess(cities));
      payload.callback({ success: true, cities: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetCitiesUniqueTrain() {
  while (true) {
    // yield put(fetchDataActionCreators.showLoader());
    const action = yield take(GET_CITIES_UNIQUE_TRAIN);
    yield* asyncGetCitiesUniqueTrain(action);
    // yield put(fetchDataActionCreators.hideLoader());
  }
}

// cities search start ------------

export function* asyncGetCitiesSearch({ payload }) {
  // eslint-disable-next-line
  const url =
    AppData.BASE_URL +
    "api/get_city?term=" +
    payload.term +
    (payload.originId ? "&origin_id=" + payload.originId : "");

  try {
    const response = yield call(App_Service, { url, method: "GET" });
    console.log("asyncGetCustomerProfile ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    payload.callback({ error: true });
    console.log(e);
  }
}

export function* watchGetCitiesSearch() {
  while (true) {
    const action = yield take(GET_CITIES_SEARCH);
    yield* asyncGetCitiesSearch(action);
  }
}
//cities search end -------------

// customer profile start ------------

export function* asyncGetCustomerProfile({ payload }) {
  console.log("payload to profil api", payload);
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/customer/profile?" + payload.operator_id;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, { url, method: "GET" });
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    console.log("asyncGetCustomerProfile ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchGetCustomerProfile() {
  while (true) {
    const action = yield take(GET_CUSTOMER_PROFILE);
    yield* asyncGetCustomerProfile(action);
  }
}
//customer profile end -------------

// update customer profile start ------------

export function* asyncUpdateCustomerProfile({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/customer/update_profile?";

  try {
    yield put(fetchDataActionCreators.showLoader());
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("asyncUpdateCustomerProfile ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchUpdateCustomerProfile() {
  while (true) {
    const action = yield take(UPDATE_CUSTOMER_PROFILE);
    yield* asyncUpdateCustomerProfile(action);
  }
}
// update customer profile end -------------

export function* asyncGetServices({ payload }) {
  // eslint-disable-next-line
  const url =
    AppData.BASE_URL +
    (CommonService.isTrain()
      ? "api/train_schedules.json?"
      : "api/ui_schedules.json?") +
    payload.path;
  console.log(url);
  try {
    if (!payload.hideLoader)
      yield put(fetchDataActionCreators.showHideBusLoader(false));
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok" && response.data.body) {
      let schedules = response.data.body;
      let keys = schedules[0];
      let schedulesArr = [];
      for (let i = 1; i < schedules.length; i++) {
        let currentObj = {};
        for (let j = 0; j < schedules[i].length; j++) {
          currentObj[keys[j]] = schedules[i][j];
        }
        schedulesArr.push(currentObj);
      }

      let res = {
        routes: schedulesArr,
        stages: schedules.stage_names,
        success: true,
        maxRetryCount: response.data.maxRetryCount,
        retryFlag: response.data.retryFlag,
        waitTime: response.data.waitTime,
      };
      payload.callback(res);
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServicesReturnSuccess(res));
      // }else{
      //   yield put(fetchDataActionCreators.getServicesSuccess(res));
      // }
      console.log(res);
      yield put(fetchDataActionCreators.showHideBusLoader(true));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.showHideBusLoader(true));
    console.log(e);
  }
}

export function* watchGetServices() {
  while (true) {
    const action = yield take(GET_SERVICES);
    yield* asyncGetServices(action);
  }
}

export function* getServiceDetails({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `api/ui_schedule.json?schedule_id=${payload.data.scheduleId}&is_round_trip=${payload.data.returnTrip}&is_roundtrip_return=${payload.data.returnTripSearch}`;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data.body) {
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServiceDetailsReturnSuccess(ReducerUtilService.makeServiceDetailsApi(response.data.body)));
      // }else{

      if (response.data && response.data.body.message) {
        payload.callback(false, response.data.body.message);
        return;
      }
      yield put(
        fetchDataActionCreators.getServiceDetailsSuccess(
          ReducerUtilService.makeServiceDetailsApi(response.data.body)
        )
      );
      // }
      payload.callback(true);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback(false);
    console.log(e);
  }
}

export function* watchGetServiceDetails() {
  while (true) {
    const action = yield take(GET_SERVICE_DETAILS);
    yield* getServiceDetails(action);
  }
}

export function* asyncGetTrainServices({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/ui_schedules.json?" + payload.path;
  console.log(url);
  try {
    yield put(fetchDataActionCreators.showHideBusLoader(false));
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok" && response.data.body) {
      let schedules = response.data.body;
      let keys = schedules[0];
      let schedulesArr = [];
      for (let i = 1; i < schedules.length; i++) {
        let currentObj = {};
        for (let j = 0; j < schedules[i].length; j++) {
          currentObj[keys[j]] = schedules[i][j];
        }
        schedulesArr.push(currentObj);
      }

      let res = {
        routes: schedulesArr,
        stages: schedules.stage_names,
        success: true,
      };
      payload.callback(res);
      console.log(res);
      yield put(fetchDataActionCreators.showHideBusLoader(true));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.showHideBusLoader(true));
    console.log(e);
  }
}

export function* watchGetTrainServices() {
  while (true) {
    const action = yield take(GET_SERVICES);
    yield* asyncGetServices(action);
  }
}

export function* bookTentativeTicket({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    (payload.V2
      ? "api/v2/bus_bookings?is_mobile_app=true"
      : !payload.isRoundTrip
      ? "api/tentative_booking.json?is_mobile_app=true"
      : "api/round_trip.json?is_mobile_app=true");
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.bookingJson,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("bookTentativeTicket", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchBookTentativeTicket() {
  while (true) {
    const action = yield take(BOOK_TENTATIVE_TICKET);
    yield* bookTentativeTicket(action);
  }
}

export function* asyncGetWalletRechargeRequest({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/wallet_recharge_request";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("getWalletRechargeRequest", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetWalletRechargeRequest() {
  while (true) {
    const action = yield take(GET_WALLET_RECHARGE_REQUEST);
    yield* asyncGetWalletRechargeRequest(action);
  }
}

export function* asyncGetRechargeLocations({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/recharge_locations?" + payload.path;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetRechargeLocations", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetRechargeLocations() {
  while (true) {
    const action = yield take(GET_RECHARGE_LOCATIONS);
    yield* asyncGetRechargeLocations(action);
  }
}

export function* asyncGetRecipientInfo({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const operator = payload.operator_id
    ? "&operator_id=" + payload.operator_id
    : "";
  const url =
    AppData.BASE_URL +
    "api/customer_info?recipient_rut_no=" +
    payload.rut +
    operator;

  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetRecipientInfo", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetRecipientInfo() {
  while (true) {
    const action = yield take(GET_RECIPIENT_INFO);
    yield* asyncGetRecipientInfo(action);
  }
}

export function* asyncUpdateFavourites({ payload }) {
  // eslint-disable-next-line
  // yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/favourites";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    // yield put(fetchDataActionCreators.hideLoader());
    console.log("getWalletRechargeRequest", response, payload);
    if (response.result === "ok" && response.data) {
      // payload.callback({success:true,data:response.data});
    }
  } catch (e) {
    // yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchUpdateFavourites() {
  while (true) {
    const action = yield take(UPDATE_FAVOURITES);
    yield* asyncUpdateFavourites(action);
  }
}

export function* asyncTransferMoney({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/transfer_money?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("TransferMoney", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchTransferMoney() {
  while (true) {
    const action = yield take(TRANSFER_MONEY);
    yield* asyncTransferMoney(action);
  }
}

export function* asyncRequestMoney({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/kupos_pay/request_money?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("RequestMoney", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchRequestMoney() {
  while (true) {
    const action = yield take(REQUEST_MONEY);
    yield* asyncRequestMoney(action);
  }
}

export function* asyncGetTicketDetails({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  // download
  const download = `${AppData.BASE_URL}api/ticket_details.json?pnr_number=${payload.pnrNumber}&is_mobile_app=true`;
  const cancel = `${AppData.BASE_URL}api/ticket_details.json?is_mobile_app=true&${payload.path}&do_cancel=true`;
  const url = payload.pnrNumber ? download : cancel;
  // AppData.BASE_URL +
  // "api/ticket_details.json?pnr_number=" +
  // payload.pnrNumber +
  // (payload.path && "&" + payload.path);

  // const url =
  //   AppData.BASE_URL +
  //   "api/ticket_details.json?is_mobile_app=true&" + payload.path + '&do_cancel=true';
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTicketDetails", response, payload);
    if (response.result === "ok" && response.data) {
      if (response.data && response.data.success && response.data.body) {
        yield put(fetchDataActionCreators.setTicketDetails(response.data.body));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
    setTimeout(() => fetchDataActionCreators.hideLoader(), 300);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTicketDetails() {
  while (true) {
    const action = yield take(GET_TICKET_DETAILS);
    yield* asyncGetTicketDetails(action);
  }
}

export function* asyncGetTicketDetailsV3({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/v3/api/ticket_details.json";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    let result;
    if (response.result === "ok" && response.data) {
      if (response && response.data && response.data.token) {
        let data = CommonService.decryptAndParse(
          response.data.token,
          "@kup" + "os@"
        );
        result = data;
      } else {
        result = response;
      }
      if (result.success && result.body) {
        yield put(fetchDataActionCreators.setTicketDetails(result.body));
      }
      if (payload.callback) payload.callback({ success: true, data: result });
    }
    setTimeout(() => fetchDataActionCreators.hideLoader(), 300);
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTicketDetailsV3() {
  while (true) {
    const action = yield take(GET_TICKET_DETAILS_V3);
    yield* asyncGetTicketDetailsV3(action);
  }
}

export function* asyncGetMyTickets({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/my_tickets?page=1&per=10&operator_id=" +
    payload.operator_id;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetMyTickets", response, payload);
    if (response.result === "ok" && response.data) {
      if (response.data && response.data.success && response.data.body) {
        yield put(
          fetchDataActionCreators.setMyTickets(response.data.body.transactions)
        );
      } else {
        yield put(fetchDataActionCreators.setMyTickets(null));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetMyTickets() {
  while (true) {
    const action = yield take(GET_MY_TICKETS);
    yield* asyncGetMyTickets(action);
  }
}

//Get Airport transfer tickets
export function* asyncGetATTickets({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.AT_URL + "api/v1/myTrips/" + payload.userId;
  try {
    const response = yield call(App_Service, { url, method: "GET", at: true });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetATTickets", response);
    if (response.result === "ok" && response.data) {
      if (response.data && response.data.success && response.data.data) {
        console.log("GetATTickets inside", response.data);
        yield put(
          fetchDataActionCreators.setATTickets(
            response.data.data.sort((a, b) =>
              a.trip_ref_date <= b.trip_ref_date ? 1 : -1
            )
          )
        );
      } else {
        yield put(fetchDataActionCreators.setATTickets(null));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetATTickets() {
  while (true) {
    const action = yield take(GET_AT_TICKETS);
    yield* asyncGetATTickets(action);
  }
}

//Get Train tickets
export function* asyncGetTrainTickets({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "/api/my_tickets?page=1&per=10&cat_type=train";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTrainTickets", response, payload);
    if (response.result === "ok" && response.data) {
      if (
        response.data &&
        response.data.success &&
        response.data.body &&
        response.data.body.transactions
      ) {
        yield put(
          fetchDataActionCreators.setTrainTickets(
            response.data.body.transactions.sort((a, b) =>
              a.travel_date <= b.travel_date ? 1 : -1
            )
          )
        );
      } else {
        yield put(fetchDataActionCreators.setTrainTickets(null));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTrainTickets() {
  while (true) {
    const action = yield take(GET_TRAIN_TICKETS);
    yield* asyncGetTrainTickets(action);
  }
}

//Get KuposGo tickets
export function* asyncGetKuposGoTickets({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL + "/api/my_tickets?page=1&per=10&cat_type=kuposgo";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetKuposGoTickets", response, payload);
    if (response.result === "ok" && response.data) {
      if (
        response.data &&
        response.data.success &&
        response.data.body &&
        response.data.body.transactions
      ) {
        yield put(
          fetchDataActionCreators.setKuposGoTickets(
            response.data.body.transactions.sort((a, b) =>
              a.travel_date <= b.travel_date ? 1 : -1
            )
          )
        );
      } else {
        yield put(fetchDataActionCreators.setKuposGoTickets(null));
      }
      if (payload.callback)
        payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetKuposGoTickets() {
  while (true) {
    const action = yield take(GET_KUPOS_GO_TICKETS);
    yield* asyncGetKuposGoTickets(action);
  }
}

//get Kupos Ticket Details
export function* asyncGetKuposTicketDetails({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `/api/kupos_ticket_details?id=${payload.id}&cat_type=${payload.cat_type}`;
  console.log("Ticket details API URL", url);
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTicketDetailsAT", response);
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetKuposTicketDetails() {
  while (true) {
    const action = yield take(GET_TICKET_DETAILS_KUPOS);
    yield* asyncGetKuposTicketDetails(action);
  }
}

export function* asyncGetTicketDetailsAT({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = payload.isCancel
    ? AppData.AT_URL + "api/v2/cancelSearch/" + payload.path
    : AppData.AT_URL + "api/v2/ticketDetails/" + payload.pnrNumber;
  try {
    const response = yield call(App_Service, { url, method: "GET", at: true });
    yield put(fetchDataActionCreators.hideLoader());
    console.log(
      "GetTicketDetailsAT-------------#############",
      response,
      payload
    );
    if (response.result === "ok" && response.data) {
      // if (response.data && response.data.result) {
      if (response.data && response.data.data) {
        yield put(fetchDataActionCreators.setTicketDetails(response.data.data));
      }
      if (response.data && response.data.data && payload.callback)
        payload.callback({ success: true, data: response.data.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTicketDetailsAT() {
  while (true) {
    const action = yield take(GET_TICKET_DETAILS_AT);
    yield* asyncGetTicketDetailsAT(action);
  }
}

//Get Environmental Things
export function* asyncwatchGetEnvironmentThings({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/env_stats";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetEnvironmentalThings", response);
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchwatchGetEnvironmentThings() {
  while (true) {
    const action = yield take(GET_ENVIRONMENT_THINGS);
    yield* asyncwatchGetEnvironmentThings(action);
  }
}

export function* asyncCancelTicket({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  // const url = AppData.BASE_URL + "api/cancel_ticket.json?" + payload.path;
  const url = payload.isV3
    ? AppData.BASE_URL + "api/v3/api/cancel_ticket"
    : AppData.BASE_URL + "api/cancel_ticket.json?" + payload.path;
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("CancelTicket", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchCancelTicket() {
  while (true) {
    const action = yield take(CANCEL_TICKET);
    yield* asyncCancelTicket(action);
  }
}

export function* asyncCancelTicketAT({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.AT_URL + "api/v2/cancel";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      at: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("CancelTicketAT", response);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchCancelTicketAT() {
  while (true) {
    const action = yield take(CANCEL_TICKET_AT);
    yield* asyncCancelTicketAT(action);
  }
}

export function* asyncGetTxnHistory({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/customer/wallets/histories?" +
    (payload.path && payload.path) +
    "&operator_id=" +
    payload.operator_id;
  // (payload.path && "&" + payload.path) + '&operator_id=' + payload.operator_id;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetTxnHistory", response, payload);
    if (response.result === "ok" && response.data) {
      if (!response.data.error)
        yield put(fetchDataActionCreators.setTxnHistory(response.data));
      payload.callback({
        success: true,
        data: response.data,
        statusCode: response.statusCode,
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetTxnHistory() {
  while (true) {
    const action = yield take(GET_TXN_HISTORY);
    yield* asyncGetTxnHistory(action);
  }
}

export function* asyncApplyPromoCode({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/apply_promo_code.json?is_mobile_app=true";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("ApplyPromoCode", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchApplyPromoCode() {
  while (true) {
    const action = yield take(APPLY_PROMO_CODE);
    yield* asyncApplyPromoCode(action);
  }
}

export function* asyncPromoCodeAirportDiscounts({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/airport_discounts?";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("PromoCodeAirportDiscounts", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchPromoCodeAirportDiscounts() {
  while (true) {
    const action = yield take(PROMO_CODE_AIRPORT_DISCOUNTS);
    yield* asyncApplyPromoCode(action);
  }
}

export function* asyncGetVirtualMoney({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    "api/customer/wallets/vw_histories?" +
    (payload.path && "&" + payload.path);
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetVirtualMoney", response, payload);
    if (response.result === "ok" && response.data) {
      if (!response.data.error)
        yield put(fetchDataActionCreators.setVirtualMoney(response.data));
      payload.callback({
        success: true,
        data: response.data,
        statusCode: response.statusCode,
      });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetVirtualMoney() {
  while (true) {
    const action = yield take(GET_VIRTUAL_MONEY);
    yield* asyncGetVirtualMoney(action);
  }
}

export function* asyncGetMarkerIcons({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/marker_icons";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("GetMarkerIcons", response, payload);
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetMarkerIcons() {
  while (true) {
    const action = yield take(GET_MARKER_ICONS);
    yield* asyncGetMarkerIcons(action);
  }
}

export function* asyncGetQrImage({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/qrcode/qr_image_data";
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      // payload.callback({success:true,data:response.data});
      yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGetQrImage() {
  while (true) {
    const action = yield take(GET_QR_IMAGE);
    yield* asyncGetQrImage(action);
  }
}

export function* asyncGetQrInfo({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/qrcode/info";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGetQrInfo() {
  while (true) {
    const action = yield take(GET_QR_INFO);
    yield* asyncGetQrInfo(action);
  }
}

export function* asyncGenerateOtp({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/generate_sms_code";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGenerateOtp() {
  while (true) {
    const action = yield take(GENERATE_OTP);
    yield* asyncGenerateOtp(action);
  }
}

export function* asyncValidateOtp({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/validate_sms_code";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchValidateOtp() {
  while (true) {
    const action = yield take(VALIDATE_OTP);
    yield* asyncValidateOtp(action);
  }
}

export function* asyncGetPlaces({ payload }) {
  const url =
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
    payload.place +
    "&key=" +
    AppData.MAP_API_KEY;
  // const url = "https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input="+payload.place+"&inputtype=textquery&fields=formatted_address,name,geometry&key="+AppData.MAP_API_KEY;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      noheader: true,
    });
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGetPlaces() {
  while (true) {
    const action = yield take(GET_PLACES);
    yield* asyncGetPlaces(action);
  }
}

// carpool api cals ----------------------------------

export function* asyncCarpoolGetSearchTrips({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-customer/trips";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolGetSearchTrips() {
  while (true) {
    const action = yield take(CARPOOL_GET_SEARCH_TRIPS);
    yield* asyncCarpoolGetSearchTrips(action);
  }
}

export function* asyncCarpoolUploadPic({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-customer/trips";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: false,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolUploadPic() {
  while (true) {
    const action = yield take(CARPOOL_UPLOAD_PIC);
    yield* asyncCarpoolUploadPic(action);
  }
}

export function* asyncCarpoolBookTrip({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "/api/carpool/customer/book";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: false,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolBookTrip() {
  while (true) {
    const action = yield take(CARPOOL_BOOK_TRIP);
    yield* asyncCarpoolBookTrip(action);
  }
}

export function* asyncCarpoolCancelTrip({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "/api/carpool/customer/cancel ";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: false,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolCancelTrip() {
  while (true) {
    const action = yield take(CARPOOL_CANCEL_TRIP);
    yield* asyncCarpoolCancelTrip(action);
  }
}

export function* asyncCarpoolRankTrip({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "/api-customer/orders/rating_driver ";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolRankTrip() {
  while (true) {
    const action = yield take(CARPOOL_RANK_TRIP);
    yield* asyncCarpoolRankTrip(action);
  }
}

export function* asyncCarpoolGetTrips({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-customer/orders?noenc=true";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolGetTrips() {
  while (true) {
    const action = yield take(CARPOOL_GET_TRIPS);
    yield* asyncCarpoolGetTrips(action);
  }
}

export function* asyncCarpoolGetOrderDetails({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.CARPOOL_URL +
    "/api-customer/orders/detail?code=" +
    payload.orderCode +
    "&noenc=true";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolGetOrderDetails() {
  while (true) {
    const action = yield take(CARPOOL_GET_ORDER_DETAILS);
    yield* asyncCarpoolGetOrderDetails(action);
  }
}

// carpool driver api cals ----------------------------------

export function* asyncCarpoolDriverRegister({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "/api/carpool/driver/register";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: false,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverRegister() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_REGISTER);
    yield* asyncCarpoolDriverRegister(action);
  }
}

export function* asyncCarpoolDriverCreateRoute({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-driver/routes";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverCreateRoute() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_CREATE_ROUTE);
    yield* asyncCarpoolDriverCreateRoute(action);
  }
}

export function* asyncCarpoolDriverCreateTrip({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-driver/trips";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverCreateTrip() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_CREATE_TRIP);
    yield* asyncCarpoolDriverCreateTrip(action);
  }
}

export function* asyncCarpoolDriverPublishTrip({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-driver/trips/publish";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverPublishTrip() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_PUBLISH_TRIP);
    yield* asyncCarpoolDriverPublishTrip(action);
  }
}

export function* asyncCarpoolDriverStartTrip({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-driver/trips/start";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverStartTrip() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_START_TRIP);
    yield* asyncCarpoolDriverStartTrip(action);
  }
}

export function* asyncCarpoolDriverArriveTrip({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-driver/trips/arrive";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverArriveTrip() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_ARRIVE_TRIP);
    yield* asyncCarpoolDriverArriveTrip(action);
  }
}

export function* asyncCarpoolDriverFinishTrip({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.CARPOOL_URL + "/api-driver/trips/finish";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverFinishTrip() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_FINISH_TRIP);
    yield* asyncCarpoolDriverFinishTrip(action);
  }
}

export function* asyncCarpoolDriverGetTrips({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.CARPOOL_URL +
    "/api-driver/trips?noenc=true" +
    (payload.statuses ? "&statuses=" + payload.statuses : "");
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverGetTrips() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_GET_TRIPS);
    yield* asyncCarpoolDriverGetTrips(action);
  }
}

export function* asyncCarpoolDriverGetTripDetails({ payload }) {
  // eslint-disable-next-line
  if (!payload.hideLoader) yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.CARPOOL_URL +
    "/api-driver/trips/detail?id=" +
    payload.id +
    "&noenc=true";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      carpool: true,
    });
    if (!payload.hideLoader) yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    if (!payload.hideLoader) yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverGetTripDetails() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_GET_TRIP_DETAILS);
    yield* asyncCarpoolDriverGetTripDetails(action);
  }
}

export function* asyncCarpoolDriverGetTripOrders({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.CARPOOL_URL +
    "/api-driver/trips/orders?id=" +
    payload.id +
    "&noenc=true";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      carpool: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchCarpoolDriverGetTripOrders() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_GET_TRIP_ORDERS);
    yield* asyncCarpoolDriverGetTripOrders(action);
  }
}

export function* asyncCarpoolDriverUpdateLocation({ payload }) {
  // eslint-disable-next-line
  const url =
    AppData.CARPOOL_URL +
    "/api-driver/accounts/update_location?lat=" +
    payload.lat +
    "&lon=" +
    payload.lon +
    "&noenc=true";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      carpool: true,
    });
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    console.log(e);
  }
}

export function* watchCarpoolDriverUpdateLocation() {
  while (true) {
    const action = yield take(CARPOOL_DRIVER_UPDATE_LOCATION);
    yield* asyncCarpoolDriverUpdateLocation(action);
  }
}

export function* asyncAirporTransferGetRoutes({ payload }) {
  // eslint-disable-next-line
  const { data } = payload;
  console.log("payload in AT search inventory-----", payload);
  yield put(fetchDataActionCreators.showLoader());
  // const url = AppData.CARPOOL_URL + `/api-customer/routes?pickup_lat=${data.pickup_lat}&pickup_long=${data.pickup_long}&destination_lat=${data.destination_lat}&destination_long=${data.destination_long}&noenc=true`;
  const url =
    AppData.BASE_URL_V3 +
    `service_inventory/${data.originLat}/${data.originLon}/${data.originLocId}/${data.utcTime}/${data.destinationLat}/${data.destinationLon}/${data.destLocId}/${data.utcReturn}/${data.kupos}/${data.currentUtc}/${data.trfrType}`;
  try {
    const response = yield call(App_Service, { url, method: "GET", at: true });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      console.log("+++ response actual is ", response);
      yield put(
        fetchDataActionCreators.airportTransferSetRoutes(
          response.data && response.data.success
            ? groupBy(response.data.data.onward_service_list, "client_code")
            : null
        )
      );

      yield put(
        fetchDataActionCreators.airportTransferSetReturnRoutes(
          response.data &&
            response.data.success &&
            response.data.data &&
            response.data.data.return_service_list
            ? groupBy(response.data.data.return_service_list, "client_code")
            : null
        )
      );
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchAirporTransferGetRoutes() {
  while (true) {
    const action = yield take(AIRPORT_TRANSFER_GET_ROUTES);
    yield* asyncAirporTransferGetRoutes(action);
  }
}

export function* asyncAirporTransferBook({ payload }) {
  // eslint-disable-next-line
  const { data } = payload;
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.AT_URL + `api/v2/booking`;
  // const url = AppData.AT_URL + `api/v1/booking`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      at: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchAirporTransferBook() {
  while (true) {
    const action = yield take(AIRPORT_TRANSFER_BOOK);
    yield* asyncAirporTransferBook(action);
  }
}

//kupos go

export function* asyncKuposGoPostTripDetails({ payload }) {
  // eslint-disable-next-line
  const { data } = payload;
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + `/api/special_trip_details`;
  // const url = `https://pbstage.kupas.cl/api/special_trip_details`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      carpool: false,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchKuposGoPostTripDetails() {
  while (true) {
    const action = yield take(KUPOS_GO_POST_TRIP_DETAILS);
    yield* asyncKuposGoPostTripDetails(action);
  }
}

// --------- BIP CARDS API START ______________________________

export function* asyncAddBipCard({ payload }) {
  // eslint-disable-next-line

  console.log("Payload is", payload.data);
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BIP_URL + "api/v1/myCard";

  console.log("URL add card is", url);
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      bip: true,
    });

    console.log("Response from add card is", response);

    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    } else {
      payload.callback({ success: false, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchAddBipCard() {
  while (true) {
    const action = yield take(ADD_BIP_CARD);
    yield* asyncAddBipCard(action);
  }
}

//Get bip cards
export function* asyncGetBipCard({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BIP_URL + "api/v1/myCard/" + payload.id;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      bip: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGetBipCard() {
  while (true) {
    const action = yield take(GET_BIP_CARDS);
    yield* asyncGetBipCard(action);
  }
}

//Update Bip Card
export function* asyncUpdateBipCard({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BIP_URL + "api/v1/myCard";
  try {
    const response = yield call(App_Service, {
      url,
      method: "PUT",
      params: payload.data,
      bip: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    console.log(e);
  }
}

export function* watchUpdateBipCard() {
  while (true) {
    const action = yield take(UPDATE_BIP_CARD);
    yield* asyncUpdateBipCard(action);
  }
}

//Delete Bip Card
export function* asyncDeleteBipCard({ payload }) {
  // eslint-disable-next-line
  console.log("Payload in delete bipcard is", payload);
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BIP_URL +
    "api/v1/myCard/" +
    payload.data.id +
    "/" +
    payload.data.user_id;

  console.log("URL in delete bipcard is", url);
  try {
    const response = yield call(App_Service, {
      url,
      method: "DELETE",
      bip: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}
export function* watchDeleteBipCard() {
  while (true) {
    const action = yield take(DELETE_BIP_CARD);
    yield* asyncDeleteBipCard(action);
  }
}

//Validate bip card
export function* asyncValidateBipCard({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BIP_URL + "api/v1/bipValidation/" + payload.cardNumber;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      bip: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchValidateBipCard() {
  while (true) {
    const action = yield take(VALIDATE_BIP_CARD);
    yield* asyncValidateBipCard(action);
  }
}

//get bip card analytics
export function* asyncGetBipCardAnalytics({ payload }) {
  console.log("Payload is", payload);
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BIP_URL +
    "api/v1/bipCardAnalytics/" +
    payload.data.email +
    "/" +
    payload.data.cardNumber;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
      bip: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }
}

export function* watchGetBipCardAnalytics() {
  while (true) {
    const action = yield take(GET_CARD_ANALYTICS);
    yield* asyncGetBipCardAnalytics(action);
  }
}

//Recharge Bip Card
export function* asyncRechargeBipCard({ payload }) {
  // eslint-disable-next-line

  console.log("Payload is", payload.data);
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BIP_URL + "api/v1/bipTransaction";

  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
      bip: true,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      payload.callback({ success: true, data: response.data });
      // yield put(fetchDataActionCreators.setQrImage(response.data));
    } else {
      payload.callback({ success: false, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    // payload.callback({success:false});
    console.log(e);
  }

  //get  bank Card
}

export function* watchRechargeBipCard() {
  while (true) {
    const action = yield take(RECHARGE_BIP_CARD);
    yield* asyncRechargeBipCard(action);
  }
}

// GET CUSTOMER CARDS --------- ONE CLICK CARDS -----------//'///////

export function* asyncGetCustomerCards({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + "api/my_cards_list?" + payload.operator_id;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, { url, method: "GET" });

    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    console.log("asyncGetCustomerCards ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetCustomerCards() {
  while (true) {
    const action = yield take(GET_CUSTOMER_CARDS);
    yield* asyncGetCustomerCards(action);
  }
}

//Add My Cards
export function* asyncwatchAddMyCards({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/kupos_add_cards?" + payload.operator_id;
  // const url = "https://www.portals.org.in/api/kupos_add_cards";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    console.log("AddCards", response);
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchAddMyCards() {
  while (true) {
    const action = yield take(ADD_MY_CARDS);
    yield* asyncwatchAddMyCards(action);
  }
}

//Delete My Cards
export function* asyncwatchDeleteMyCards({ payload }) {
  // console.log("Payload of delete card api", payload)

  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/remove_kupos_card";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());

    // console.log("Delete cards api response", response);

    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchDeleteMyCards() {
  while (true) {
    const action = yield take(DELETE_MY_CARDS);
    yield* asyncwatchDeleteMyCards(action);
  }
}

// passengerInfo(card_type, id_card_number): Observable<any> {
//   return this.getUrl(this.prepareApi2('api/passenger_info?', 'id_card_type=' + card_type + '&id_card_number=' + id_card_number)).map(res => res.json());
// }

export function* asyncGetCustomerInfo({ payload }) {
  // eslint-disable-next-line
  const url =
    AppData.BASE_URL +
    "api/passenger_info?id_card_type=" +
    payload.cardType +
    "&id_card_number=" +
    payload.idCardNumber;

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, {
      url,
      method: "GET",
      isPassengerInfo: true,
    });

    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    console.log("asyncGetCustomerInfo ----------------->", response);
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetCustomerInfo() {
  while (true) {
    const action = yield take(GET_CUSTOMER_INFO);
    yield* asyncGetCustomerInfo(action);
  }
}

//GET AIRPORT TRANSFER TERMINALS
export function* asyncGetATTerminals({ payload }) {
  const url = AppData.AT_URL + "api/v1/airportLocations";

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, { url, method: "GET", at: true });

    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetATTerminals() {
  while (true) {
    const action = yield take(GET_AT_TERMINALS);
    yield* asyncGetATTerminals(action);
  }
}

//GET AIRPORT SEAT TYPES
export function* asyncGetSeatTypes({ payload }) {
  const url = AppData.AT_URL + "api/v1/master_data/SEAT/TYPE";

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, { url, method: "GET", at: true });

    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetSeatTypes() {
  while (true) {
    const action = yield take(GET_SEAT_TYPES);
    yield* asyncGetSeatTypes(action);
  }
}

//GET AIRPORT TIME BLOCK
export function* asyncGetATBlockTime({ payload }) {
  const url = AppData.AT_URL + "api/v1/master_data/INTERVAL/DELFOS_BOOKING";

  try {
    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.showLoader());
    }
    const response = yield call(App_Service, { url, method: "GET", at: true });

    if (!payload.hideLoader) {
      yield put(fetchDataActionCreators.hideLoader());
    }
    if (response.result === "ok" && response.data) {
      payload.callback(response);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
  }
}

export function* watchGetATBlockTime() {
  while (true) {
    const action = yield take(GET_AT_BLOCK_TIME);
    yield* asyncGetATBlockTime(action);
  }
}

//GET INSTANT DISCOUNT
export function* asyncGetInstantDscount({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api_instant_discounts/apply_instant_discount";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetInstantDscount() {
  while (true) {
    const action = yield take(GET_INSTANT_DISCOUNT);
    yield* asyncGetInstantDscount(action);
  }
}

//Corporate DISCOUNT
export function* asyncGetCorporateDscount({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL + "api_corporate_discounts/apply_corporate_discount";
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: payload.data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetCorporateDscount() {
  while (true) {
    const action = yield take(GET_CORPORATE_DISCOUNT);
    yield* asyncGetCorporateDscount(action);
  }
}

//Corporate DISCOUNT
export function* asyncGetOperatorSite({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/operator_site_details";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetOperatorSite() {
  while (true) {
    const action = yield take(GET_OPERATOR_SITE);
    yield* asyncGetOperatorSite(action);
  }
}

// office_details
export function* asyncGetOfficeDetails({ payload }) {
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + "api/office_details";
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchGetOfficeDetails() {
  while (true) {
    const action = yield take(GET_OFFICE_DETAILS);
    yield* asyncGetOfficeDetails(action);
  }
}

// --- new apis
export function* asyncGetServicesV2({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + `api/v2/op_scheduled_services?` + payload.path;

  console.log(url);
  try {
    if (!payload.hideLoader)
      yield put(fetchDataActionCreators.showHideBusLoader(false));
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok" && response.data) {
      payload.callback(response.data);
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServicesReturnSuccess(res));
      // }else{
      //   yield put(fetchDataActionCreators.getServicesSuccess(res));
      // }
      console.log(response);
      yield put(fetchDataActionCreators.showHideBusLoader(true));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.showHideBusLoader(true));
    console.log(e);
  }
}

export function* watchGetServicesV2() {
  while (true) {
    const action = yield take(GET_SERVICES_V2);
    yield* asyncGetServicesV2(action);
  }
}

export function* getServiceDetailsV2({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL + `api/v2/scheduled_services/${payload.data.scheduleId}`;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data.body) {
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServiceDetailsReturnSuccess(ReducerUtilService.makeServiceDetailsApi(response.data.body)));
      // }else{

      if (response.data && response.data.body.message) {
        payload.callback(false, response.data.body.message, response.data);
        return;
      }
      yield put(
        fetchDataActionCreators.getServiceDetailsSuccess(
          ReducerUtilService.makeServiceDetailsApi(response.data.body)
        )
      );
      // }
      payload.callback(true, "", response.data.body);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback(false);
    console.log(e);
  }
}

export function* watchGetServiceDetailsV2() {
  while (true) {
    const action = yield take(GET_SERVICE_DETAILS_V2);
    yield* getServiceDetailsV2(action);
  }
}

// --- new apis
export function* asyncGetServicesV3({ payload }) {
  // eslint-disable-next-line
  const url = AppData.BASE_URL + `api/v3/op_scheduled_services?` + payload.path;

  console.log(url);
  try {
    if (!payload.hideLoader)
      yield put(fetchDataActionCreators.showHideBusLoader(false));
    const response = yield call(App_Service, { url, method: "GET" });

    if (response.result === "ok" && response.data) {
      payload.callback(response.data);
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServicesReturnSuccess(res));
      // }else{
      //   yield put(fetchDataActionCreators.getServicesSuccess(res));
      // }
      console.log(response);
      yield put(fetchDataActionCreators.showHideBusLoader(true));
    }
  } catch (e) {
    yield put(fetchDataActionCreators.showHideBusLoader(true));
    console.log(e);
  }
}

export function* watchGetServicesV3() {
  while (true) {
    const action = yield take(GET_SERVICES_V3);
    yield* asyncGetServicesV3(action);
  }
}

export function* getServiceDetailsV3({ payload }) {
  // eslint-disable-next-line
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `api/v3/op_scheduled_services/${payload.data.scheduleId}`;
  try {
    const response = yield call(App_Service, { url, method: "GET" });
    yield put(fetchDataActionCreators.hideLoader());
    if (response?.data?.token) {
      let data = CommonService.decryptAndParse(
        response.data.token,
        "@kup" + "os@"
      );
      response.data = data;
    }
    if (response.result === "ok" && response.data.body) {
      // if(payload.isReturn){
      //   yield put(fetchDataActionCreators.getServiceDetailsReturnSuccess(ReducerUtilService.makeServiceDetailsApi(response.data.body)));
      // }else{

      if (response.data && response.data.body.message) {
        payload.callback(false, response.data.body.message, response.data);
        return;
      }
      yield put(
        fetchDataActionCreators.getServiceDetailsSuccess(
          ReducerUtilService.makeServiceDetailsApi(response.data.body)
        )
      );
      // }
      payload.callback(true, "", response.data.body);
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback(false);
    console.log(e);
  }
}

export function* watchGetServiceDetailsV3() {
  while (true) {
    const action = yield take(GET_SERVICE_DETAILS_V3);
    yield* getServiceDetailsV3(action);
  }
}

// MOT
export function* asyncValidateMotCoupon({ payload }) {
  console.log("Payload is---", payload);
  let data = payload.data;
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `api/services/validate_mot_coupon.json?schedule_id=${data.schedule_id}&mot_coupon=${data.motNumber}&mobile_no=${data.phone}`;
  // `api/services/validate_mot_coupon.json?schedule_id=${data.schedule_id}&mot_coupon=${data.motNumber}&mobile_no=${data.phone}`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchValidateMotCoupon() {
  while (true) {
    const action = yield take(VALIDATE_MOT);
    yield* asyncValidateMotCoupon(action);
  }
}

export function* asyncValidateMotSMS({ payload }) {
  let data = payload.data;
  yield put(fetchDataActionCreators.showLoader());
  const url =
    AppData.BASE_URL +
    `api/services/validate_sms_code.json?code=${data.code}&mot_code=${data.motNumber}`;
  // `api/services/validate_mot_coupon.json?schedule_id=${data.schedule_id}&mot_coupon=${data.motNumber}&mobile_no=${data.phone}`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "GET",
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchValidateMotSMS() {
  while (true) {
    const action = yield take(VALIDATE_MOT_SMS);
    yield* asyncValidateMotSMS(action);
  }
}

export function* asyncSpecialServiceAPi({ payload }) {
  let data = payload.data;
  console.log("🚀 ~ function*asyncSpecialServiceAPi ~ data:", data);
  yield put(fetchDataActionCreators.showLoader());
  const url = AppData.BASE_URL + `api/special_service_request`;
  // `api/services/validate_mot_coupon.json?schedule_id=${data.schedule_id}&mot_coupon=${data.motNumber}&mobile_no=${data.phone}`;
  try {
    const response = yield call(App_Service, {
      url,
      method: "POST",
      params: data,
    });
    yield put(fetchDataActionCreators.hideLoader());
    if (response.result === "ok" && response.data) {
      if (payload.callback) payload.callback({ success: true, data: response });
    }
  } catch (e) {
    yield put(fetchDataActionCreators.hideLoader());
    payload.callback({ success: false });
    console.log(e);
  }
}

export function* watchSpecialServiceAPi() {
  while (true) {
    const action = yield take(SPECIAL_SERVIVCE);
    yield* asyncSpecialServiceAPi(action);
  }
}
export default function* () {
  yield all([
    fork(watchGetApiCall),
    fork(watchPostApiCall),
    fork(watchPutApiCall),
    fork(watchDeleteApiCall),
    fork(watchGetFacebookUserData),
    fork(watchLogin),
    fork(watchOpsiteContactInfo),
    fork(watchRegister),
    fork(watchGoogleLogout),
    fork(watchForgotPassword),
    fork(watchValidateCustomer),
    fork(watchAccountConfirmation),
    fork(watchResetNewPassword),
    fork(watchGetMetaData),
    fork(watchGetCities),
    fork(watchGetCitiesUnique),
    fork(watchGetCitiesUniqueTrain),
    fork(watchGetCityPairs),
    fork(watchGetCitiesSearch),
    fork(watchGetCustomerProfile),
    fork(watchUpdateCustomerProfile),
    fork(watchGetServices),
    fork(watchGetServicesV2),
    fork(watchGetServicesV3),
    fork(watchGetServiceDetails),
    fork(watchGetServiceDetailsV2),
    fork(watchGetServiceDetailsV3),
    fork(watchBookTentativeTicket),
    fork(watchGetWalletRechargeRequest),
    fork(watchGetRechargeLocations),
    fork(watchGetRecipientInfo),
    fork(watchUpdateFavourites),
    fork(watchTransferMoney),
    fork(watchRequestMoney),
    fork(watchGetTicketDetails),
    fork(watchGetTicketDetailsV3),
    fork(watchGetMyTickets),
    fork(watchGetATTickets),
    fork(watchGetTrainTickets),
    fork(watchGetKuposGoTickets),
    fork(watchGetKuposTicketDetails),
    fork(watchwatchGetEnvironmentThings),
    fork(watchCancelTicket),
    fork(watchCancelTicketAT),
    fork(watchGetTxnHistory),
    fork(watchGetVirtualMoney),
    fork(watchApplyPromoCode),
    fork(watchGetMarkerIcons),
    fork(watchGetQrImage),
    fork(watchGetQrInfo),
    fork(watchGetPlaces),
    fork(watchGenerateOtp),
    fork(watchValidateOtp),
    fork(watchPromoCodeAirportDiscounts),

    //carpool apis -------------
    fork(watchCarpoolGetSearchTrips),
    fork(watchCarpoolUploadPic),
    fork(watchCarpoolBookTrip),
    fork(watchCarpoolCancelTrip),
    fork(watchCarpoolRankTrip),
    fork(watchCarpoolGetTrips),
    fork(watchCarpoolGetOrderDetails),

    //carpool driver ----------------
    fork(watchCarpoolDriverRegister),
    fork(watchCarpoolDriverCreateRoute),
    fork(watchCarpoolDriverCreateTrip),
    fork(watchCarpoolDriverPublishTrip),
    fork(watchCarpoolDriverStartTrip),
    fork(watchCarpoolDriverArriveTrip),
    fork(watchCarpoolDriverFinishTrip),
    fork(watchCarpoolDriverGetTrips),
    fork(watchCarpoolDriverGetTripDetails),
    fork(watchCarpoolDriverGetTripOrders),
    fork(watchCarpoolDriverUpdateLocation),

    //airport transfer --------------
    fork(watchAirporTransferGetRoutes),
    fork(watchAirporTransferBook),

    //kupos go
    fork(watchKuposGoPostTripDetails),
    fork(watchGetTicketDetailsAT),

    //Bip APIS---------------
    fork(watchAddBipCard),
    fork(watchGetBipCard),
    fork(watchUpdateBipCard),
    fork(watchDeleteBipCard),
    fork(watchRechargeBipCard),
    fork(watchValidateBipCard),
    fork(watchGetBipCardAnalytics),

    //ONE CLICK CARDS
    fork(watchGetCustomerCards),
    fork(watchAddMyCards),
    fork(watchDeleteMyCards),

    fork(watchGetCustomerInfo),
    fork(watchGetATTerminals),
    fork(watchGetSeatTypes),
    fork(watchGetATBlockTime),

    //INSTANT DISCOUNT
    fork(watchGetInstantDscount),

    //MOT
    fork(watchValidateMotCoupon),
    fork(watchValidateMotSMS),

    //CORPORATE DISCOUNT
    fork(watchGetCorporateDscount),
    fork(watchGetOperatorSite),
    fork(watchGetOfficeDetails),

    fork(watchSpecialServiceAPi),
  ]);
}
